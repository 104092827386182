import React from "react";

export default function DemoTest() {
  const user = sessionStorage.getItem("data");
  const items = [
    {
      srNo: 1,
      name: "Pool Sand Filter TM625",
      hsn: "84219900",
      qty: 2,
      rate: 13000.0,
      igst: 18.0,
      amount: 26000.0,
    },
    {
      srNo: 2,
      name: "Pool Sand Filter TM700",
      hsn: "84219900",
      qty: 2,
      rate: 15700.0,
      igst: 18.0,
      amount: 31400.0,
    },
    {
      srNo: 3,
      name: "Pool Sand Filter TM800",
      hsn: "84219900",
      qty: 1,
      rate: 20600.0,
      igst: 18.0,
      amount: 20600.0,
    },
    {
      srNo: 4,
      name: "Pool Sand Filter TM900",
      hsn: "84219900",
      qty: 1,
      rate: 23900.0,
      igst: 18.0,
      amount: 23900.0,
    },
    {
      srNo: 5,
      name: "Pool Deep Net",
      hsn: "39269099",
      qty: 20,
      rate: 440.0,
      igst: 18.0,
      amount: 8800.0,
    },
    {
      srNo: 6,
      name: 'Pool Wall Brush 18" Delux Curved',
      hsn: "96039000",
      qty: 20,
      rate: 260.0,
      igst: 18.0,
      amount: 5200.0,
    },
    {
      srNo: 7,
      name: "Pool Main Drain Round",
      hsn: "39269099",
      qty: 20,
      rate: 240.0,
      igst: 18.0,
      amount: 4800.0,
    },
    {
      srNo: 8,
      name: 'Pool Aluminum Vaccum Head 13.5"',
      hsn: "96039000",
      qty: 4,
      rate: 1860.0,
      igst: 18.0,
      amount: 7440.0,
    },
    {
      srNo: 9,
      name: 'Pool Aluminum Vaccum Head 17.5"',
      hsn: "96039000",
      qty: 4,
      rate: 2360.0,
      igst: 18.0,
      amount: 9450.0,
    },
    {
      srNo: 10,
      name: "Pool Filter Bag",
      hsn: "5602",
      qty: 3,
      rate: 850.0,
      igst: 18.0,
      amount: 2550.0,
    },
  ];
  return (
    <div className="container  p-5">
      <div
        className="border border-2 border-black"
        style={{ height: "", width: "80%" }}
      >
        <header className="">
          <div
            style={{ backgroundColor: "lightgray" }}
            className="border-bottom border-2 border-black py-2 text-center fs-5 fw-bold"
          >
            VINMAR POOLS
          </div>

          <div className="border-bottom border-2 border-black py-1 text-center fs-6 fw-bold d-flex justify-content-between ">
            <p className="mb-0">Debit Memo</p>
            <p className="mb-0">TAX INVOICE</p>
            <p className="mb-0">Original</p>
          </div>
          <div className="row border-bottom border-1 border-black m-0">
            <div
              style={{ fontSize: "13px", fontWeight: "500" }}
              className=" col-9  py-1  border-end border-1 border-black py-3 d-flex "
            >
              <div>
                <p className="mb-1 fs-6 fw-bold">Head Office :</p>
                <p className="mb-0 fs-6 fw-bold"> VINMAR POOLS </p>
                <p className="mb-0 "> 477, E.C.R ROAD KANATHUR </p>
                <p className="mb-0 "> Kancheepuram, </p>
                <p className="mb-0 "> Chennai - 603103 </p>

                <p className="mb-0 ">
                  <span className=" fw-bold ">GST No. </span>33CAHPS1111H1Z3
                </p>
                <p className="mb-0 ">
                  <span className=" fw-bold">E-MAIL : </span>
                  vinmarpoolsindia@gmail.com
                </p>
                <p className="mb-0  ">
                  <span className=" fw-bold">MOB NO : </span>
                  9840385085
                </p>
                <p className="mb-0  ">
                  <span className=" fw-bold">WEB SITE : </span>
                  WWW.VINMARPOOLS.COM
                </p>
              </div>
              <div className="ps-3">
                <p className="mb-1 fs-6 fw-bold">Branch Office :</p>
                <p className="mb-0 fs-6 fw-bold"> VINMAR POOLS </p>
                <p className="mb-0 "> 477, E.C.R ROAD KANATHUR </p>
                <p className="mb-0 "> Kancheepuram, </p>
                <p className="mb-0 "> Chennai - 603103 </p>

                <p className="mb-0 ">
                  <span className=" fw-bold ">GST No. </span>33CAHPS1111H1Z3
                </p>
                <p className="mb-0 ">
                  <span className=" fw-bold">E-MAIL : </span>
                  vinmarpoolsindia@gmail.com
                </p>
                <p className="mb-0  ">
                  <span className=" fw-bold">MOB NO : </span>
                  9840385085
                </p>
                <p className="mb-0  ">
                  <span className=" fw-bold">WEB SITE : </span>
                  WWW.VINMARPOOLS.COM
                </p>
              </div>
            </div>

            <div className="col-3  p-0 d-flex flex-column justify-content-between">
              <div
                style={{ height: "152px" }}
                className=" d-flex justify-content-center align-items-center"
              >
                <img
                  style={{
                    height: "130px",
                    borderRadius: "20px",
                  }}
                  src={`data:image/jpeg;base64,${user}`}
                  alt="logo"
                />
              </div>
              <div
                style={{ backgroundColor: "lightgray" }}
                className="px-2 border-top border-2 border-black"
              >
                <p className="mb-0  fw-bold">Invoice No : PMAX/541</p>
                <p className="mb-0  fw-bold">Date : 12/07/2024</p>
              </div>
            </div>
          </div>
          <div className="border-bottom border-1 border-black px-2 py-1">
            <p className=" mb-0">
              <span className="fw-bold">Bill address : </span>RATAN IRON
              COMPANY, 3C , ATHIPALAYAM,KEERANATHAM,COIMBATORE 641035
            </p>
          </div>
        </header>
        <section>
          {/* <div style={{height:"600px"}}> */}
            <table
              style={{ width: "100%" }}
              className="border-bottom border-black"
            >
              <thead className="border-bottom  border-black">
                <th
                  style={{ fontSize: "14px", width: "5%" }}
                  className="text-center p-1 fw-bold border-end  border-black"
                >
                  SrNo
                </th>
                <th
                  style={{ fontSize: "14px", width: "55%" }}
                  className="text-center p-1 fw-bold border-end  border-black"
                >
                  {" "}
                  NAME OF THE PRODUCT/SERVICES
                </th>
                <th
                  style={{ fontSize: "14px", width: "10%" }}
                  className="text-center p-1 fw-bold border-end  border-black"
                >
                  {" "}
                  QTY
                </th>
                <th
                  style={{ fontSize: "14px", width: "10%" }}
                  className="text-center p-1 fw-bold border-end  border-black"
                >
                  {" "}
                  HSN CD
                </th>
                <th
                  style={{ fontSize: "14px", width: "10%" }}
                  className="text-center p-1 fw-bold border-end  border-black"
                >
                  {" "}
                  RATE
                </th>
                <th
                  style={{ fontSize: "14px", width: "10%" }}
                  className="text-center p-1 fw-bold"
                >
                  {" "}
                  AMOUNT
                </th>
              </thead>

              {/* <div style={{height:"700px",width:"100%",backgroundColor:"yellowgreen"}}> */}
              {items.map((item, i) => {
                return (
                  <>
                    <tbody style={{ width: "100%" }}>
                      <th
                        style={{ fontSize: "14px" }}
                        className="py-2 text-center border-end  border-black"
                      >
                        {i + 1}
                      </th>
                      <th
                        style={{ fontSize: "14px" }}
                        className="py-2 text-center border-end  border-black"
                      >
                        {item.name}
                      </th>
                      <th
                        style={{ fontSize: "14px" }}
                        className="py-2 text-center border-end  border-black"
                      >
                        {item.qty}
                      </th>
                      <th
                        style={{ fontSize: "14px" }}
                        className="py-2 text-center border-end  border-black"
                      >
                        {item.hsn}
                      </th>
                      <th
                        style={{ fontSize: "14px" }}
                        className="py-2 text-center border-end  border-black"
                      >
                        {item.rate}
                      </th>
                      <th
                        style={{ fontSize: "14px" }}
                        className="py-2 text-center "
                      >
                        {item.amount}
                      </th>
                    </tbody>
                  </>
                );
              })}
              {/* </div> */}
            </table>
          <div
            style={{ backgroundColor: "lightgray" }}
            className=" border-bottom border-black d-flex justify-content-end"
          >
            <div style={{ width: "30%" }} className="">
              <div className="d-flex justify-content-">
                <p className="mb-0 fw-bold ps-3" style={{ width: "67%" }}>
                  Sub Total
                </p>

                <p
                  className="mb-0 fw-bold  text-center"
                  style={{ width: "34%" }}
                >
                  00000
                </p>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
        <footer>
          <div className="">
            <div className="d-flex">
              <div
                style={{ width: "70%" }}
                className="border-end border-bottom border-black ps-3 py-1"
              >
                <table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th> Current Acc Holder Name </th>
                      <th>: VINMAR POOLS</th>
                    </tr>
                    <tr>
                      <th> Bank Name </th>
                      <th>: INDIAN OVERSEAS BANK</th>
                    </tr>
                    <tr>
                      <th> A/C No </th>
                      <th>: 282202000000020</th>
                    </tr>
                    <tr>
                      <th> IFSC Code </th>
                      <th>: IOBA0002822</th>
                    </tr>
                    <tr>
                      <th> Branch </th>
                      <th>: KANATHUR REDDYKUPPAM</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                style={{ width: "30%" }}
                className="pb-3 d-flex flex-column justify-content-center"
              >
                <div className="d-flex pb-2">
                  <p className="mb-0 fw-bold ps-3" style={{ width: "67%" }}>
                    Taxable Amount
                  </p>

                  <p
                    className="mb-0 fw-bold  text-center"
                    style={{ width: "34%" }}
                  >
                    00000
                  </p>
                </div>
                <div className="d-flex justify-content-">
                  <p className="mb-0  ps-3" style={{ width: "67%" }}>
                    Integrated Tax 18%
                  </p>
                  {/* <p className="mb-0  " style={{ width: "30%" }}>
                    9%
                  </p> */}
                  <p className="mb-0   text-center" style={{ width: "34%" }}>
                    00000
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                style={{ width: "70%" }}
                className="border-end border-bottom border-black ps-3 py-1"
              >
                <i>
                  <span className="fw-bold">Bill Amount :</span>
                  One Lakh Seventy Eight Thousand Seventy Four Only
                </i>
              </div>
              <div
                style={{ width: "30%" }}
                className="border-bottom border-black"
              ></div>
            </div>
            <div className="d-flex">
              <div
                style={{ width: "70%" }}
                className="border-end border-bottom border-black ps-3 py-1"
              >
                <p className="mb-0 fw-bold">Note :</p>
              </div>
              <div
                style={{
                  width: "30%",
                  height: "70px",
                  backgroundColor: "lightgray",
                }}
                className="border-bottom border-black"
              >
                <div className="d-flex pb-2 py-3">
                  <p className="mb-0 fw-bold ps-3  " style={{ width: "67%" }}>
                    Grand Total
                  </p>

                  <p
                    className="mb-0 fw-bold  text-center"
                    style={{ width: "34%" }}
                  >
                    00000
                  </p>
                </div>
              </div>
            </div>
            <div className="ps-3 d-flex justify-content-between">
              <div>
                <p className="mb-0 fw-bold">Terms & Condition :</p>
                <i>
                  1. Goods once sold will not be taken back.
                  <br />
                  2. Interest @18% p.a. will be charged if payment is not made
                  within due date.
                  <br />
                  3. Our risk and responsibility ceases as soon as the goods
                  leave our premises.
                  <br />
                  4. "Subject to 'RAJKOT' Jurisdiction only. E.&.O.E"
                </i>
              </div>
              <div className="pe-3 d-flex flex-column justify-content-between">
                <p className="mb-0">For, Vinmar Pools</p>
                <p className="mb-0 pb-1">(Authority Signatory)</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
{/* <div>
                <div className="d-flex border-bottom border-black">
                  <div
                    className="py-2 "
                    style={{
                      fontSize: "16px",
                      width: "5%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    S.No
                  </div>
                  <div
                    className="py-2 "
                    style={{
                      fontSize: "16px",
                      width: "55%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    NAME OF THE PRODUCT/SERVICES
                  </div>
                  <div
                    className="py-2 "
                    style={{
                      fontSize: "14px",
                      width: "10%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    QTY
                  </div>
                  <div
                    className="py-2 "
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    HSN CD
                  </div>
                  <div
                    className="py-2 "
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    RATE
                  </div>
                  <div
                    className="py-2 "
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    AMOUNT
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex" style={{ height: "700px" }}>
                  <div
                    style={{
                      fontSize: "16px",
                      width: "5%",
                      // fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                    // className="py-2"
                  >
                    {sessionValue?.userForm?.map((items, i) => {
                      return (
                        <div className="py-2">
                          <span>{i + 1}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      width: "55%",
                      height: "auto",
                      // fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                    className="text-break"
                  >
                    {sessionValue?.userForm?.map((items, i) => {
                      return (
                        <div className="py-2">
                          <span>{items?.productName}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      // fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    {sessionValue?.userForm?.map((items, i) => {
                      return (
                        <div className="py-2">
                          <span>{items?.quantity}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      // fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    {sessionValue?.userForm?.map((items, i) => {
                      return (
                        <div className="py-2">
                          <span>{items?.hsn}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      // fontWeight: "bold",
                      textAlign: "center",
                      borderRight: "2px solid black",
                    }}
                  >
                    {sessionValue?.userForm?.map((items, i) => {
                      return (
                        <div className="py-2">
                          <span>{items?.unitPrice}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      width: "10%",
                      // fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {sessionValue?.userForm?.map((items, i) => {
                      totalPrice =
                        Number(sessionValue?.userForm[i]?.actualPrice) +
                        totalPrice;

                      return (
                        <div className="py-2">
                          <span>{items?.actualPrice}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}
              {/* </div> */}